<template>
  <div class="w-full mt-20 md:mt-0">
    
    <div
      class="flex items-center justify-between mb-4 bg-verdedark text-white font-bold w-full fixed md:static"
    >
      <span class="ml-2">Editando {{ nombreVendedor }}</span>

      <div class="flex justify-center">
        <router-link to="/AgregarProveedor"
          ><button
            class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              class="h-6 w-6 mr-2 text-white fill-current"
              xml:space="preserve"
            >
              <path
                d="M166,332h180c8.284,0,15-6.716,15-15s-6.716-15-15-15H166c-8.284,0-15,6.716-15,15S157.716,332,166,332z"
              />
              <path
                d="M166,392h180c8.284,0,15-6.716,15-15s-6.716-15-15-15H166c-8.284,0-15,6.716-15,15S157.716,392,166,392z"
              />
              <path
                d="M507.606,84.394l-80-80C424.793,1.58,420.978,0,417,0H15C6.716,0,0,6.716,0,15v482c0,8.284,6.716,15,15,15 c6.912,0,477.495,0,482,0c8.284,0,15-6.716,15-15V95C512,91.021,510.419,87.206,507.606,84.394z M121,30h210v100H121V30z    M391,482H121V272h270V482z M482,482h-61V257c0-8.284-6.716-15-15-15H106c-8.284,0-15,6.716-15,15v225H30V30h61v115  c0,8.284,6.716,15,15,15h240c8.284,0,15-6.716,15-15V30h49.787L482,101.213V482z"
              />
              <path
                d="M166,452h180c8.284,0,15-6.716,15-15s-6.716-15-15-15H166c-8.284,0-15,6.716-15,15S157.716,452,166,452z"
              />
            </svg>
            <span @click="guardarDataPersonal()" class="underline"
              >Guardar cambios</span
            >
          </button></router-link
        >
      </div>
    </div>
    <!----------------Tabla de datos Personales---------------------------------------------------->
    <div class="flex w-full mt-4 justify-center ml-5 md:ml-0">
      <div class="mt-4 mb-10 w-full">
        <div class="block md:flex items-center justify-between w-full text-2xl">
          <div class="flex items-center mt-6 md:mt-0">
            <svg
              class="fill-current text-white rounded-full bg-gray-400 w-12 h-12 md:w-24 md:h-24 pt-1 inline-flex ml-6"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
            >
              <path
                d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148 C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962 c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216 h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40 c59.551,0,108,48.448,108,108S315.551,256,256,256z"
              />
            </svg>
          </div>
          <div class="flex items-center font-bold mt-6 md:mt-0">
            <span>Estado:</span>
            <select
              name="estado"
              v-model="vendedorQuery.disponible"
              class="px-1 ml-2 rounded-lg focus:outline-none text-white font-bold"
              :class="
                vendedorQuery.disponible === '1' ? 'bg-green-500' : 'bg-red-500'
              "
            >
              <option value="1">Disponible</option>
              <option value="0">No disponible</option>
            </select>
          </div>
          <div class="flex items-center font-bold mt-6 md:mt-0">
            <span>% Comisión:</span>
            <div>
              <input
                type="text"
                class="focus:outline-none mx-2 rounded-lg w-20 px-1 border border-secundB bg-secund"
                v-model="vendedorQuery.comision"
              />
            </div>
          </div>
          <div class="flex items-center mr-6 mt-6 md:mt-0">
            <span class="font-bold">Nomina:</span>
            <select
              name="estado"
              v-model="vendedorQuery.fijo"
              class="px-1 ml-2 rounded-lg focus:outline-none"
            >
              <option value="1">Fijo</option>
              <option value="0">No fijo</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full mt-4 justify-center ml-30 md:ml-0">
      <table class="table-fixed ml-72 md:ml-0">
        <tr>
          <th colspan="2" class="border-2 px-4 py-2 bg-gray-300">
            Información personal
          </th>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Nombre y apellido
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.user.name"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">C.I.</th>
          <td class="border-2 px-4 py-2 w-64">
            <div class="flex content-center">
              <select
                id="select"
                class="ml-1 bg-white"
                @change="cedulaPrefijoDatosPersonales($event)"
              >
                <option disabled selected value="">{{
                  prefijoCedula(vendedorQuery.user.cedula_identidad)
                }}</option>
                <option value="V">V</option>
                <option value="E">E</option>
              </select>
              <input
                class="ml-1"
                type="text"
                v-model="nuevoObjeto.cedula_identidad"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Telefono movil</th>
          <td class="border-2 px-4 py-2 w-64">
            <div class="flex content-center">
              <select
                id="select"
                @change="telefonoMovilPrefijo($event)"
                class="ml-1 bg-white"
              >
                <option disabled selected value="">{{
                  prefijoTelefono(vendedorQuery.user.numero_telefono_movil)
                }}</option>
                <option value="0412">0412</option>
                <option value="0414">0414</option>
                <option value="0424">0424</option>
                <option value="0416">0416</option>
                <option value="0426">0426</option>
              </select>
              <input
                class="ml-1"
                type="text"
                v-model="nuevoObjeto.numero_telefono_movil"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Telefono local</th>
          <td class="border-2 px-4 py-2 w-64 content-center">
            0212 -
            <input
              class="ml-1"
              type="text"
              v-model="vendedorQuery.user.numero_telefono_local"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Dirección</th>
          <td class="border-2 px-4 py-2 w-64 content-center">
            <textarea
              class="ml-1 w-full "
              type="text"
              v-model="vendedorQuery.user.address"
            >
            </textarea>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Email</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="email"
              v-model="vendedorQuery.user.email"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Carta de residencia
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <a
              v-if="mostrarArchivoCartaResidencia"
              class="hover:text-blue-500 hover:underline pb-10"
              :href="
                `process.env.VUE_APP_BACKEND_URL`+ '/storage' +
                  vendedorQuery.carta_residencia.slice(
                    6,
                    vendedorQuery.carta_residencia.length
                  )
              "
              target="_blank"
              :download="'Carta Residencia ' + vendedorQuery.user.name"
              >Descargar Archivo Actual</a
            >
            <input
              class="w-full focus:outline-none"
              @change="subirArchivoCartaResidencia"
              type="file"
            />
            <div v-if="cargando1">
              <p>{{ mensajeCarga1 }}</p>
            </div>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Permisos
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.permisos"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Referencia personal 1
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.nombre_referencias_1"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Referencia personal 1 numero
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.telefono_referencias_1"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Referencia personal 2
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.nombre_referencias_2"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Referencia personal 2 numero
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.telefono_referencias_2"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Seguro médico</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.seguro_medico"
            />
          </td>
        </tr>
      </table>

      <!--------------------------------------Tabla de vehiculo-------------------------------------------------->
      <table class="table-fixed mx-10">
        <tr>
          <th colspan="2" class="border-2 px-4 py-2 bg-gray-300">
            Datos vehículo
          </th>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Modelo</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.modelo_vehiculo"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Placa</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.placa_vehiculo"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Carnet de circulación
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.carnet_circulacion"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Licencia</th>
          <td class="border-2 px-4 py-2 w-64">
            <a
              v-if="mostrarArchivoLicencia"
              class="hover:text-blue-500 hover:underline pb-10"
              :href="
                'https://shoppersback.pedidoslist.com/storage' +
                  vendedorQuery.licencia.slice(6, vendedorQuery.licencia.length)
              "
              target="_blank"
              :download="'Licencia ' + vendedorQuery.user.name"
              >Descargar Archivo Actual</a
            >
            <input
              class="w-full focus:outline-none"
              @change="subirArchivoLicencia"
              type="file"
            />
            <div v-if="cargando2">
              <p>{{ mensajeCarga2 }}</p>
            </div>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">RCV</th>
          <td class="border-2 px-4 py-2 w-64">
            <a
              v-if="mostrarArchivoRCV"
              class="hover:text-blue-500 hover:underline pb-10"
              :href="
                `process.env.VUE_APP_BACKEND_URL` + '/storage' +
                  vendedorQuery.rcv.slice(6, vendedorQuery.rcv.length)
              "
              target="_blank"
              :download="'RCV ' + vendedorQuery.user.name"
              >Descargar Archivo Actual</a
            >
            <input
              class="w-full focus:outline-none"
              @change="subirArchivoRCV"
              type="file"
            />
            <div v-if="cargando3">
              <p>{{ mensajeCarga3 }}</p>
            </div>
          </td>
        </tr>
        <tr>
          <th colspan="2" class="border-2 px-4 py-2 bg-gray-300">
            Datos bancarios
          </th>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Banco</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.user.datos_bancarios[0].banco_1"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Tipo de cuenta</th>
          <td class="border-2 px-4 py-2 w-64">
            <select
              name="estado"
              v-model="vendedorQuery.user.datos_bancarios[0].tipo_cuenta_1"
              class="px-1 ml-2 focus:outline-none bg-white"
            >
              <option value="0">Ahorro</option>
              <option value="1">Corriente</option>
            </select>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Numero de cuenta</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.user.datos_bancarios[0].numero_cuenta_1"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Banco pago movil</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.user.datos_bancarios[0].banco_movil"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">
            Numero Pago Movil
          </th>
          <td class="border-2 px-4 py-2 w-64">
            <div class="flex content-center">
              <select
                id="select"
                @change="telefonoBancaMovilPrefijo($event)"
                class="ml-1 bg-white"
              >
                <option disabled selected value="">{{
                  prefijoTelefono(
                    vendedorQuery.user.datos_bancarios[0].numero_telefono
                  )
                }}</option>
                <option value="0412">0412</option>
                <option value="0414">0414</option>
                <option value="0424">0424</option>
                <option value="0416">0416</option>
                <option value="0426">0426</option>
              </select>
              <input
                class="ml-1"
                type="text"
                v-model="nuevoObjeto.banca_movil"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">C.I. pago movil</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.user.datos_bancarios[0].cedula_movil"
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="flex w-full mt-4 justify-center">
      <table class="table-fixed mx-10">
        <tr>
          <th colspan="2" class="border-2 px-4 py-2 bg-gray-300">
            Acceso a Plataforma
          </th>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Usuario</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="vendedorQuery.user.email"
            />
          </td>
        </tr>
        <tr>
          <th class="bg-gray-100 border-2 px-4 py-2">Contraseña</th>
          <td class="border-2 px-4 py-2 w-64">
            <input
              class="w-full focus:outline-none"
              type="text"
              v-model="password"
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="w-full my-4 items-center justify-center">
      <div v-if="showMessage" class="py-10 flex justify-center">
        <p>{{ message }}</p>
      </div>
      <div class="flex w-full content-center items-center justify-center">
        <Botonp
          @click="guardarOpcion()"
          class="underline"
          botonpText="Guardar cambios"
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            class=" h-6 w-6 mr-2 text-botonT fill-current"
            xml:space="preserve"
          >
            <path
              d="M166,332h180c8.284,0,15-6.716,15-15s-6.716-15-15-15H166c-8.284,0-15,6.716-15,15S157.716,332,166,332z"
            />
            <path
              d="M166,392h180c8.284,0,15-6.716,15-15s-6.716-15-15-15H166c-8.284,0-15,6.716-15,15S157.716,392,166,392z"
            />
            <path
              d="M507.606,84.394l-80-80C424.793,1.58,420.978,0,417,0H15C6.716,0,0,6.716,0,15v482c0,8.284,6.716,15,15,15 c6.912,0,477.495,0,482,0c8.284,0,15-6.716,15-15V95C512,91.021,510.419,87.206,507.606,84.394z M121,30h210v100H121V30z    M391,482H121V272h270V482z M482,482h-61V257c0-8.284-6.716-15-15-15H106c-8.284,0-15,6.716-15,15v225H30V30h61v115  c0,8.284,6.716,15,15,15h240c8.284,0,15-6.716,15-15V30h49.787L482,101.213V482z"
            />
            <path
              d="M166,452h180c8.284,0,15-6.716,15-15s-6.716-15-15-15H166c-8.284,0-15,6.716-15,15S157.716,452,166,452z"
            />
          </svg>
        </Botonp>
      </div>
    </div>
  </div>
</template>

<script>
import Botonp from "@/components/botonPrimario";
import gql from "graphql-tag";

const newLocal = 15;
export default {
  data: function() {
    return {
      mensajeCarga1: "",
      cargando1: false,
      mensajeCarga2: "",
      cargando2: false,
      mensajeCarga3: "",
      cargando3: false,
      message: "",
      showMessage: false,
      primeraVez: true,
      Vendedores: [
        {
          Nombre: "",
          CedulaTipo: "",
          Cedula: "",
          TelefonoMovil: "",
          TelefonoLocal: "",
          Direccion: "",
          LinkDireccion: "",
          Email: "",
          CartaResidencia: "",
          ReferenciaPersonal1: "",
          ReferenciaPersonal2: "",
          ReferenciaPersonal3: "",
          SeguroMedico: "",
          Modelo: "",
          Placa: "",
          CarnetCirculación: "",
          Licencia: "",
          Rcv: "",
          Banco: "",
          TipoCuenta: "",
          NumeroCuenta: "",
          BancoMovil: "",
          NumeroBancoMovil: "",
          CedulaBancoMovil: "",
          Usuario: "",
          Contrasena: "",
          operadora: null,
          vendedorQuery: []
        }
      ],
      vendedorQuery: {},
      prefijoObjecto: {
        datosPersonalesCI: "",
        pagoMovilCI: "",
        datoBancario1CI: "",
        datoBancario2CI: "",
        pagoMovilTelefono: "",
        telefonoPersonal: ""
      },
      nuevoObjeto: {
        cedula_identidad: "",
        numero_telefono_movil: "",
        datos_bancarios: ""
      },
      mostrarArchivoCartaResidencia: false,
      mostrarArchivoRCV: false,
      mostrarArchivoLicencia: false,
      tipoArchivo: "",
      password: "",
      nombreVendedor: ""
    };
  },
  components: {
    Botonp
  },
  async mounted() {
    if (this.$route.params.var) {
      this.$store.state.isLoading = true;
      await this.cargarDataPersonal();
      this.nombreVendedor = this.$route.params.nombre.replaceAll("_", " ")
    } else {
      this.dataVacia();
    }
  },
  methods: {
    cedulaPrefijoDatosPersonales(event) {
      this.prefijoObjecto.datosPersonalesCI = event.target.value;
    },
    telefonoBancaMovilPrefijo(event) {
      this.prefijoObjecto.pagoMovilTelefono = event.target.value;
    },
    telefonoMovilPrefijo(event) {
      this.prefijoObjecto.telefonoPersonal = event.target.value;
    },
    prefijoTelefono(numero) {
      return numero[0] + numero[1] + numero[2] + numero[3];
    },
    prefijoCedula(numero) {
      return numero[0];
    },
    addVendedor() {
      this.Vendedores.push({
        Nombre: "",
        cedula: "",
        TelefonoMovil: "",
        TelefonoLocal: "",
        Direccion: "",
        LinkDireccion: "",
        Email: "",
        CartaResidencia: "",
        ReferenciaPersonal1: "",
        ReferenciaPersonal2: "",
        ReferenciaPersonal3: "",
        SeguroMedico: "",
        Modelo: "",
        Placa: "",
        CarnetCirculacion: "",
        Licencia: "",
        Rcv: "",
        Banco: "",
        TipoCuenta: "",
        NumeroCuenta: "",
        BancoMovil: "",
        NumeroBancoMovil: "",
        CedulaBancoMovil: "",
        Usuario: "",
        Contrasena: ""
      });
    },
    cortarString(valor, index) {
      let nuevoString = "";
      nuevoString = valor.toString();
      nuevoString = nuevoString.substring(index);
      return nuevoString;
    },
    datosGenerales: async function() {
      this.nuevoObjeto.cedula_identidad = await JSON.parse(
        JSON.stringify(this.vendedorQuery.user.cedula_identidad.toString())
      );

      this.prefijoObjecto.datosPersonalesCI = this.prefijoCedula(
        this.nuevoObjeto.cedula_identidad
      );

      this.nuevoObjeto.cedula_identidad = this.cortarString(
        this.nuevoObjeto.cedula_identidad,
        1
      );

      this.nuevoObjeto.numero_telefono_movil = await JSON.parse(
        JSON.stringify(this.vendedorQuery.user.numero_telefono_movil.toString())
      );

      this.prefijoObjecto.telefonoPersonal = this.prefijoTelefono(
        this.nuevoObjeto.numero_telefono_movil
      );

      this.nuevoObjeto.numero_telefono_movil = this.cortarString(
        this.nuevoObjeto.numero_telefono_movil,
        4
      );

      this.nuevoObjeto.banca_movil = JSON.parse(
        JSON.stringify(
          this.vendedorQuery.user.datos_bancarios[0].numero_telefono.toString()
        )
      );

      this.prefijoObjecto.pagoMovilTelefono = this.prefijoTelefono(
        this.nuevoObjeto.banca_movil
      );
      this.nuevoObjeto.banca_movil = this.cortarString(
        this.nuevoObjeto.banca_movil,
        4
      );
    },
    cargarDataPersonal: async function() {
      this.$store.state.isLoading = true;
      await this.$apollo
        .query({
          query: gql`
            query vendedorId($id: ID!) {
              vendedorId(id: $id) {
                id
                user_id
                fijo
                carta_residencia
                licencia
                rcv
                carnet_circulacion
                modelo_vehiculo
                placa_vehiculo
                permisos
                comision
                nombre_referencias_1
                telefono_referencias_1
                nombre_referencias_2
                telefono_referencias_2
                seguro_medico
                disponible
                user {
                  id
                  name
                  cedula_identidad
                  numero_telefono_movil
                  numero_telefono_local
                  address
                  email
                  datos_bancarios {
                    id
                    user_id
                    nombre_1
                    cedula_1
                    banco_1
                    numero_cuenta_1
                    tipo_cuenta_1
                    banco_movil
                    numero_telefono
                    cedula_movil
                  }
                }
              }
            }
          `,
          variables: {
            id: parseInt(this.$route.params.var)
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.vendedorQuery = data.data.vendedorId[0];

          if (this.vendedorQuery.carta_residencia != "") {
            this.mostrarArchivoCartaResidencia = true;
          }

          if (this.vendedorQuery.licencia != "") {
            this.mostrarArchivoLicencia = true;
          }

          if (this.vendedorQuery.rcv != "") {
            this.mostrarArchivoRCV = true;
          }
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.$store.state.isLoading = false;
        });
      this.datosGenerales();
    },
    guardarDataPersonal: async function() {
      let data = {};
      data = this.preGuardadoData();
      this.$store.state.isLoading = true;
      await this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation guardarVendedor($id: ID!, $data: UsersUpdate!) {
              guardarVendedor(id: $id, input: $data) {
                __typename
              }
            }
          `,
          variables: {
            id: this.vendedorQuery.user.id,
            data: data
          },
          fetchPolicy: "no-cache"
        })
        .then(data => {
          this.message = "Datos Guardados Exitosamente";
          this.showMessage = true;
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          console.error(error);
          this.message = error;
          this.showMessage = true;
          this.$store.state.isLoading = false;
        });
    },
    subirArchivoCartaResidencia: async function({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.mensajeCarga1 = "Subiendo Archivo...";
      this.cargando1 = true;
      this.$store.state.isLoading = true;
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/uploadFile.gql"),
          variables: {
            file: files[0],
            oldFile: String(this.vendedorQuery.carta_residencia)
          },
          context: {
            hasUpload: true
          }
        })
        .then(data => {
          this.vendedorQuery.carta_residencia = data.data.uploadFiles;
          this.mostrarArchivoCartaResidencia = true;
          this.mensajeCarga1 = "Completado";
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.mensajeCarga1 = error;
          this.$store.state.isLoading = false;
        });
    },
    subirArchivoLicencia: async function({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }

      this.mensajeCarga2 = "Subiendo Archivo...";
      this.cargando2 = true;
      this.$store.state.isLoading = true;
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/uploadFile.gql"),
          variables: {
            file: files[0],
            oldFile: String(this.vendedorQuery.licencia)
          },
          context: {
            hasUpload: true
          }
        })
        .then(data => {
          this.vendedorQuery.licencia = data.data.uploadFiles;
          this.mostrarArchivoLicencia = true;
          this.mensajeCarga2 = "Completado";
          this.$store.state.isLoading = false;
        })
        .catch(error => {
          this.$store.state.isLoading = false;
          this.mensajeCarga2 = error;
        });
    },
    subirArchivoRCV: async function({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }

      this.mensajeCarga3 = "Subiendo Archivo...";
      this.cargando3 = true;
      this.$store.state.isLoading = true;
      await this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/uploadFile.gql"),
          variables: {
            file: files[0],
            oldFile: String(this.vendedorQuery.rcv)
          },
          context: {
            hasUpload: true
          }
        })
        .then(data => {
          this.$store.state.isLoading = false;
          this.vendedorQuery.rcv = data.data.uploadFiles;
          this.mostrarArchivoRCV = true;
          this.mensajeCarga3 = "Completado";
        })
        .catch(error => {
          this.mensajeCarga3 = error;
          this.$store.state.isLoading = false;
        });
    },
    tipoArchivoDescargar(archivo) {
      this.tipoArchivoDescargar = archivo;
    },
    dataVacia() {
      this.vendedorQuery = {
        id: "",
        user_id: "",
        fijo: "1",
        carta_residencia: "",
        licencia: "",
        rcv: "",
        carnet_circulacion: "",
        modelo_vehiculo: "",
        placa_vehiculo: "",
        permisos: "",
        comision: "",
        nombre_referencias_1: "",
        telefono_referencias_1: "",
        nombre_referencias_2: "",
        telefono_referencias_2: "",
        seguro_medico: "",
        disponible: "1",
        user: {
          id: "",
          name: "",
          cedula_identidad: "",
          numero_telefono_movil: "",
          numero_telefono_local: "",
          address: "",
          email: "",
          datos_bancarios: [
            {
              id: "",
              user_id: "",
              nombre_1: "",
              cedula_1: "",
              banco_1: "",
              numero_cuenta_1: "",
              tipo_cuenta_1: "",
              banco_movil: "",
              numero_telefono: "",
              cedula_movil: ""
            }
          ]
        }
      };
    },
    guardarOpcion() {
      if (this.$route.params.var) {
        this.guardarDataPersonal();
      } else {
        this.guardarDataPersonalNueva();
      }
    },
    guardarDataPersonalNueva: async function() {
      if (!this.primeraVez) {
        this.message = "Por favor editar data en la página del vendedor";
        this.showMessage = true;
      } else {
        let data = {};
        this.primeraVez = false;
        data = this.preGuardadoDataNuevo();
        this.$store.state.isLoading = true;
        await this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation guardarVendedorNuevo($data: UsersUpdate!) {
                guardarVendedorNuevo(input: $data) {
                  __typename
                }
              }
            `,
            variables: {
              data: data
            },
            fetchPolicy: "no-cache"
          })
          .then(() => {
            this.$store.state.isLoading = false;
            this.message = "Datos Guardados Exitosamente";
            this.showMessage = true;
          })
          .catch(error => {
            console.error(error);
            this.message = error;
            this.showMessage = true;
            this.primeraVez = true;
            this.$store.state.isLoading = false;
          });
      }
    },
    preGuardadoDataNuevo() {
      let data;
      data = {
        name: this.vendedorQuery.user.name,
        cedula_identidad:
          this.prefijoObjecto.datosPersonalesCI +
          this.nuevoObjeto.cedula_identidad,
        email: this.vendedorQuery.user.email,
        numero_telefono_movil:
          this.prefijoObjecto.telefonoPersonal +
          this.nuevoObjeto.numero_telefono_movil,
        numero_telefono_local: this.vendedorQuery.user.numero_telefono_local,
        address: this.vendedorQuery.user.address,
        password: this.password,
        roles: {
          connect: [{ role_id: 4 }]
        },
        datos_bancarios: {
          create: [
            {
              nombre_1: this.vendedorQuery.user.name,
              banco_1: this.vendedorQuery.user.datos_bancarios[0].banco_1,
              cedula_1:
                this.prefijoObjecto.datosPersonalesCI +
                this.nuevoObjeto.cedula_identidad,
              numero_cuenta_1: this.vendedorQuery.user.datos_bancarios[0]
                .numero_cuenta_1,
              tipo_cuenta_1: this.vendedorQuery.user.datos_bancarios[0]
                .tipo_cuenta_1,
              banco_movil: this.vendedorQuery.user.datos_bancarios[0]
                .banco_movil,
              numero_telefono:
                this.prefijoObjecto.pagoMovilTelefono +
                this.nuevoObjeto.banca_movil,
              cedula_movil:
                this.prefijoObjecto.datosPersonalesCI +
                this.nuevoObjeto.cedula_identidad
            }
          ]
        },
        vendedores: {
          create: [
            {
              fijo: this.vendedorQuery.fijo,
              carta_residencia: this.vendedorQuery.carta_residencia,
              nombre_referencias_1: this.vendedorQuery.nombre_referencias_1,
              licencia: this.vendedorQuery.licencia,
              permisos: this.vendedorQuery.permisos,
              telefono_referencias_1: this.vendedorQuery.telefono_referencias_1,
              nombre_referencias_2: this.vendedorQuery.nombre_referencias_2,
              telefono_referencias_2: this.vendedorQuery.telefono_referencias_2,
              disponible: this.vendedorQuery.disponible,
              modelo_vehiculo: this.vendedorQuery.modelo_vehiculo,
              placa_vehiculo: this.vendedorQuery.placa_vehiculo,
              carnet_circulacion: this.vendedorQuery.carnet_circulacion,
              rcv: this.vendedorQuery.rcv,
              seguro_medico: this.vendedorQuery.seguro_medico,
              comision: this.vendedorQuery.comision
            }
          ]
        }
      };
      return data;
    },
    preGuardadoData() {
      let data;
      if (this.password === "") {
        data = {
          name: this.vendedorQuery.user.name,
          cedula_identidad:
            this.prefijoObjecto.datosPersonalesCI +
            this.nuevoObjeto.cedula_identidad,
          email: this.vendedorQuery.user.email,
          numero_telefono_movil:
            this.prefijoObjecto.telefonoPersonal +
            this.nuevoObjeto.numero_telefono_movil,
          numero_telefono_local: this.vendedorQuery.user.numero_telefono_local,
          address: this.vendedorQuery.user.address,
          datos_bancarios: {
            update: [
              {
                id: parseInt(this.vendedorQuery.user.datos_bancarios[0].id),
                nombre_1: this.vendedorQuery.user.name,
                banco_1: this.vendedorQuery.user.datos_bancarios[0].banco_1,
                cedula_1: this.vendedorQuery.user.datos_bancarios[0].cedula_1,
                numero_cuenta_1: this.vendedorQuery.user.datos_bancarios[0]
                  .numero_cuenta_1,
                tipo_cuenta_1: this.vendedorQuery.user.datos_bancarios[0]
                  .tipo_cuenta_1,
                banco_movil: this.vendedorQuery.user.datos_bancarios[0]
                  .banco_movil,
                numero_telefono:
                  this.prefijoObjecto.pagoMovilTelefono +
                  this.nuevoObjeto.banca_movil,
                cedula_movil:
                  this.prefijoObjecto.datosPersonalesCI +
                  this.nuevoObjeto.cedula_identidad
              }
            ]
          },
          vendedores: {
            update: [
              {
                id: parseInt(this.vendedorQuery.id),
                fijo: this.vendedorQuery.fijo,
                carta_residencia: this.vendedorQuery.carta_residencia,
                nombre_referencias_1: this.vendedorQuery.nombre_referencias_1,
                licencia: this.vendedorQuery.licencia,
                permisos: this.vendedorQuery.permisos,
                telefono_referencias_1: this.vendedorQuery
                  .telefono_referencias_1,
                nombre_referencias_2: this.vendedorQuery.nombre_referencias_2,
                telefono_referencias_2: this.vendedorQuery
                  .telefono_referencias_2,
                disponible: this.vendedorQuery.disponible,
                modelo_vehiculo: this.vendedorQuery.modelo_vehiculo,
                placa_vehiculo: this.vendedorQuery.placa_vehiculo,
                carnet_circulacion: this.vendedorQuery.carnet_circulacion,
                rcv: this.vendedorQuery.rcv,
                seguro_medico: this.vendedorQuery.seguro_medico,
                comision: this.vendedorQuery.comision
              }
            ]
          }
        };
      } else {
        data = {
          name: this.vendedorQuery.user.name,
          cedula_identidad:
            this.prefijoObjecto.datosPersonalesCI +
            this.nuevoObjeto.cedula_identidad,
          email: this.vendedorQuery.user.email,
          numero_telefono_movil:
            this.prefijoObjecto.telefonoPersonal +
            this.nuevoObjeto.numero_telefono_movil,
          numero_telefono_local: this.vendedorQuery.user.numero_telefono_local,
          address: this.vendedorQuery.user.address,
          password: this.password,
          datos_bancarios: {
            update: [
              {
                id: parseInt(this.vendedorQuery.user.datos_bancarios[0].id),
                nombre_1: this.vendedorQuery.user.name,
                banco_1: this.vendedorQuery.user.datos_bancarios[0].banco_1,
                cedula_1: this.vendedorQuery.user.datos_bancarios[0].cedula_1,
                numero_cuenta_1: this.vendedorQuery.user.datos_bancarios[0]
                  .numero_cuenta_1,
                tipo_cuenta_1: this.vendedorQuery.user.datos_bancarios[0]
                  .tipo_cuenta_1,
                banco_movil: this.vendedorQuery.user.datos_bancarios[0]
                  .banco_movil,
                numero_telefono: String(
                  this.prefijoObjecto.pagoMovilTelefono +
                    this.nuevoObjeto.banca_movil
                ),
                cedula_movil:
                  this.prefijoObjecto.datosPersonalesCI +
                  this.nuevoObjeto.cedula_identidad
              }
            ]
          },
          vendedores: {
            update: [
              {
                id: parseInt(this.vendedorQuery.id),
                fijo: this.vendedorQuery.fijo,
                carta_residencia: this.vendedorQuery.carta_residencia,
                nombre_referencias_1: this.vendedorQuery.nombre_referencias_1,
                licencia: this.vendedorQuery.licencia,
                permisos: this.vendedorQuery.permisos,
                telefono_referencias_1: this.vendedorQuery
                  .telefono_referencias_1,
                nombre_referencias_2: this.vendedorQuery.nombre_referencias_2,
                telefono_referencias_2: this.vendedorQuery
                  .telefono_referencias_2,
                disponible: this.vendedorQuery.disponible,
                modelo_vehiculo: this.vendedorQuery.modelo_vehiculo,
                placa_vehiculo: this.vendedorQuery.placa_vehiculo,
                carnet_circulacion: this.vendedorQuery.carnet_circulacion,
                rcv: this.vendedorQuery.rcv,
                seguro_medico: this.vendedorQuery.seguro_medico,
                comision: this.vendedorQuery.comision
              }
            ]
          }
        };
      }
      return data;
    }
  }
};
</script>
